import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ROUTES } from "./routes";
import { Login } from "./pages/Login/Login";
import { SplashScreen } from "./components/molecules/SplashScreen/SplashScreen";
import { PrivateRoute } from "./components/atoms/PrivateRoute";

const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const UsersPage = lazy(() => import("./pages/Users/Users"));
const MowerPage = lazy(() => import("./pages/Mower/Mower"));
const UserDetailPage = lazy(() => import("./pages/Users/UserDetail"));
const UfonPage = lazy(() => import("./pages/Ufon/Ufon"));
const DevicesPage = lazy(() => import("./pages/Devices/Devices"));
const RoutesPage = lazy(() => import("./pages/Routes/Routes"));
const RoutePage = lazy(() => import("./pages/Route/Route"));
const RouteEditPage = lazy(() => import("./pages/Routes/RouteEdit"));
const NotFound = lazy(() => import("./pages/404/NotFound"));
const JobsPage = lazy(() => import("./pages/Jobs/Jobs"));
const JobPage = lazy(() => import("./pages/Job/Job"));
const JobsCreatePage = lazy(() => import("./pages/Jobs/JobsCreate"));
const MowerEditPage = lazy(() => import("./pages/Mower/MowerEdit"));
const MowerCreatePage = lazy(() => import("./pages/Mower/MowerCreate"));
const JobSignpostPage = lazy(() => import("./pages/Job/JobSignpost"));
const UfonCreatePage = lazy(() => import("./pages/Ufon/UfonCreate"));
const UfonEditPage = lazy(() => import("./pages/Ufon/UfonEdit"));
const UserCreatePage = lazy(() => import("./pages/Users/UserCreate"));
const UserEditPage = lazy(() => import("./pages/Users/UserEdit"));
const UserSelectCompanyPage = lazy(
  () => import("./pages/Users/UserSelectCompany")
);
const RouteCreatePage = lazy(() => import("./pages/Routes/RouteCreate"));
const LeicasPage = lazy(() => import("./pages/Leicas/Leicas"));
const LeicaCreatePage = lazy(() => import("./pages/Leica/LeicaCreate"));
const LeicaPage = lazy(() => import("./pages/Leica/Leica"));
const CompaniesPage = lazy(() => import("./pages/Companies/Companies"));
const JobEditPage = lazy(() => import("./pages/Jobs/JobEdit"));
const LostPasswordPage = lazy(
  () => import("./pages/LostPassword/LostPassword")
);
const UserRegisterPage = lazy(
  () => import("./pages/UserRegister/UserRegister")
);
const RecoverPasswordPage = lazy(
  () => import("./pages/RecoverPassword/RecoverPassword")
);
const PendingUserDetailPage = lazy(
  () => import("./pages/Users/PendingUserDetail")
);
const JoinCompany = lazy(() => import("./pages/JoinCompany/JoinCompany"));
const RouteClonePage = lazy(() => import("./pages/Routes/RouteClone"));
const JobRouteType = lazy(() => import("./pages/JobRouteType/JobRouteType"));
const JobManualPage = lazy(() => import("./pages/JobManual/JobManual"));
const StatisticsPage = lazy(() => import("./pages/Statistics/Statistics"));
const Geostick = lazy(() => import("./pages/Geostick/Geostick"));
const RouteChangePage = lazy(() => import("./pages/Routes/RouteChange"));
const RouteCreateFromJobPage = lazy(
  () => import("./pages/Routes/RouteCreateFromJob")
);
const NotificationsPage = lazy(
  () => import("./pages/Notifications/Notifications")
);
const CSTPage = lazy(() => import("./pages/CST/CST"));
const CSTEditPage = lazy(() => import("./pages/CST/CSTEdit"));
const DeviceRegisterPage = lazy(
  () => import("./pages/DeviceRegister/DeviceRegister")
);
const DeviceRegisterPublicPage = lazy(
  () => import("./pages/DeviceRegister/DeviceRegisterPublic")
);
const DeviceRegisterLogin = lazy(
  () => import("./pages/DeviceRegister/DeviceRegisterLogin")
);
const Support = lazy(() => import("./pages/Support/Support"));

export const history = createBrowserHistory();

const Routing = () => {
  return (
    <HistoryRouter history={history}>
      {/* <InjectAxiosInterceptors /> */}
      <Suspense fallback={<SplashScreen />}>
        <Routes>
          {/* Main routes */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path={ROUTES.login()} element={<Login />} />
          <Route
            path="/user/select-company"
            element={
              <PrivateRoute>
                <UserSelectCompanyPage />
              </PrivateRoute>
            }
          />

          {/* User routes */}
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <UsersPage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.userCreate()}
            element={
              <PrivateRoute>
                <UserCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/user/:userId"
            element={
              <PrivateRoute>
                <UserDetailPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/pending-user/:userId"
            element={
              <PrivateRoute>
                <PendingUserDetailPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/user/edit/:userId"
            element={
              <PrivateRoute>
                <UserEditPage />
              </PrivateRoute>
            }
          />
          <Route path={ROUTES.lostPassword()} element={<LostPasswordPage />} />
          <Route
            path={"/user-register/:token"}
            element={<UserRegisterPage />}
          />
          <Route path={"/user-register"} element={<UserRegisterPage />} />
          <Route
            path={"/recover-password/:token"}
            element={<RecoverPasswordPage />}
          />
          <Route path={"/approve/:token"} element={<JoinCompany />} />
          <Route
            path={ROUTES.support()}
            element={
              <PrivateRoute>
                <Support />
              </PrivateRoute>
            }
          />

          {/* Devices routes */}
          <Route
            path="/mower/create/"
            element={
              <PrivateRoute>
                <MowerCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/mower/:mowerId"
            element={
              <PrivateRoute>
                <MowerPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/mower/edit/:mowerId"
            element={
              <PrivateRoute>
                <MowerEditPage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.deviceRegister()}
            element={
              <PrivateRoute>
                <DeviceRegisterPage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.deviceRegisterPublic()}
            element={<DeviceRegisterPublicPage />}
          />
          <Route
            path={ROUTES.deviceRegisterLogin()}
            element={<DeviceRegisterLogin />}
          />
          <Route
            path={ROUTES.notifications()}
            element={
              <PrivateRoute>
                <NotificationsPage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.ufonCreate()}
            element={
              <PrivateRoute>
                <UfonCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/ufon/:ufonId"
            element={
              <PrivateRoute>
                <UfonPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/ufon/edit/:ufonId"
            element={
              <PrivateRoute>
                <UfonEditPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/devices"
            element={
              <PrivateRoute>
                <DevicesPage />
              </PrivateRoute>
            }
          />

          {/* Routes routes */}
          <Route
            path="/routes"
            element={
              <PrivateRoute>
                <RoutesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/route/:routeId"
            element={
              <PrivateRoute>
                <RoutePage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.routeCreate()}
            element={
              <PrivateRoute>
                <RouteCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/route/edit/:routeId"
            element={
              <PrivateRoute>
                <RouteEditPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/route/create-from-job/:jobId"
            element={
              <PrivateRoute>
                <RouteCreateFromJobPage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.routeClone()}
            element={
              <PrivateRoute>
                <RouteClonePage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.routeChange()}
            element={
              <PrivateRoute>
                <RouteChangePage />
              </PrivateRoute>
            }
          />
          <Route
            path="route/cst/:cstId"
            element={
              <PrivateRoute>
                <CSTPage />
              </PrivateRoute>
            }
          />
          <Route
            path="route/cst/edit/:cstId"
            element={
              <PrivateRoute>
                <CSTEditPage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.leicaCreate()}
            element={
              <PrivateRoute>
                <LeicaCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.leicas()}
            element={
              <PrivateRoute>
                <LeicasPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/leica/:leicaId/:leicaName"
            element={
              <PrivateRoute>
                <LeicaPage />
              </PrivateRoute>
            }
          />

          {/* Jobs routes */}
          <Route
            path="/jobs"
            element={
              <PrivateRoute>
                <JobsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/job-signpost/:jobId"
            element={
              <PrivateRoute>
                <JobSignpostPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/job/:jobId"
            element={
              <PrivateRoute>
                <JobPage />
              </PrivateRoute>
            }
          />
          <Route
            path={"/job/create"}
            element={
              <PrivateRoute>
                <JobsCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path={"/job/create/:jobId"}
            element={
              <PrivateRoute>
                <JobsCreatePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/job/edit/:jobId"
            element={
              <PrivateRoute>
                <JobEditPage />
              </PrivateRoute>
            }
          />

          {/* Companies routes */}
          <Route
            path="/companies"
            element={
              <PrivateRoute>
                <CompaniesPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/route-job/:jobId"
            element={
              <PrivateRoute>
                <JobRouteType />
              </PrivateRoute>
            }
          />

          <Route
            path="/manual-job/:jobId"
            element={
              <PrivateRoute>
                <JobManualPage />
              </PrivateRoute>
            }
          />

          <Route
            path="geostick-job/:jobId"
            element={
              <PrivateRoute>
                <Geostick />
              </PrivateRoute>
            }
          />

          <Route
            path="/statistics"
            element={
              <PrivateRoute>
                <StatisticsPage />
              </PrivateRoute>
            }
          />

          {/* 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </HistoryRouter>
  );
};

export default Routing;
