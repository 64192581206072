import { Preferences } from "@capacitor/preferences";

const REFRESH_TOKEN = "refresh_token";
const ACCESS_TOKEN = "access_token";
const TOKEN_EXPIRATION_DATE = "token_expiration_date";
const COMPANY_ID = "company_id";

export type TokensT = {
  accessToken: string;
  refreshToken: string;
  tokenExpirationDate: Date;
};

export const setTokens = async (
  accessToken: string,
  refreshToken: string,
  acExpirationDate: Date
) => {
  await Promise.all([
    Preferences.set({
      key: TOKEN_EXPIRATION_DATE,
      value: acExpirationDate.toISOString(),
    }),
    Preferences.set({ key: REFRESH_TOKEN, value: refreshToken }),
    Preferences.set({ key: ACCESS_TOKEN, value: accessToken }),
  ]);
};

export const getTokens: () => Promise<TokensT | null> = async () => {
  const [
    { value: accessToken },
    { value: refreshToken },
    { value: acExpirationDate },
  ] = await Promise.all([
    Preferences.get({ key: ACCESS_TOKEN }),
    Preferences.get({ key: REFRESH_TOKEN }),
    Preferences.get({ key: TOKEN_EXPIRATION_DATE }),
  ]);

  if (!accessToken || !refreshToken || !acExpirationDate) {
    return null;
  }

  return {
    accessToken,
    refreshToken,
    tokenExpirationDate: new Date(acExpirationDate),
  };
};

export const removeTokens = async () => {
  await Promise.all([
    Preferences.remove({ key: ACCESS_TOKEN }),
    Preferences.remove({ key: REFRESH_TOKEN }),
    Preferences.remove({ key: TOKEN_EXPIRATION_DATE }),
  ]);
};

export const setCompany = async (companyId: number) => {
  await Preferences.set({ key: COMPANY_ID, value: `${companyId}` });
};

export const getCompany = async () => {
  const { value: companyId } = await Preferences.get({ key: COMPANY_ID });
  if (companyId) {
    return parseInt(companyId);
  }
  return null;
};

export const removeCompany = async () => {
  await Preferences.remove({ key: COMPANY_ID });
};
