import { AxiosResponse } from "axios";
import { addSeconds } from "date-fns";
import { ROUTES } from "../routes";
import { client } from "../services/axios";
import { firebaseUnregisterDevice } from "../services/firebase";
import { store } from "../store";
import { storeClear } from "../store/actions";
import { UserCurrentT } from "../types/User";
import { history } from "../Routing";
import {
  getTokens,
  removeCompany,
  removeTokens,
  setTokens,
} from "./localStorage";
import { CLIENT_SECRET } from "../env";
import { queryClient } from "..";

export const CLIENT_ID = "bootiq";
export const GRANT_TYPES = {
  password: "password",
  refreshToken: "refresh_token",
};
export const TOKEN_ROTATION_BUFFER = 60; // 1 minute (s)

export type AuthMutation = {
  access_token: string;
  expires_in: number; // seconds
  refresh_token: string;
  token_type: string;
};

export const rotateTokens = async (): Promise<AuthMutation> => {
  const tokens = await getTokens();
  const formData = new FormData();
  formData.append("grant_type", GRANT_TYPES.refreshToken);
  formData.append("client_id", CLIENT_ID);
  formData.append("client_secret", CLIENT_SECRET);
  formData.append("refresh_token", tokens?.refreshToken as string);

  const { data }: AxiosResponse<AuthMutation> = await client.post(
    "/api/oauth2/token",
    formData
  );

  const {
    expires_in: expiresIn,
    access_token: accessToken,
    refresh_token: newRefreshToken,
  } = data;
  const timeToExpiration = expiresIn - TOKEN_ROTATION_BUFFER; // seconds

  const accessTokenExpirationDate = addSeconds(new Date(), timeToExpiration);
  await setTokens(accessToken, newRefreshToken, accessTokenExpirationDate);
  return data;
};

export const getUserData = async (): Promise<UserCurrentT> => {
  const { data }: AxiosResponse<UserCurrentT> = await queryClient.fetchQuery(
    "current-user",
    () => client.get(`/api/v1/user/current`)
  );
  return data;
};

export const logoutUser = async () => {
  store.dispatch(storeClear());
  await removeTokens();
  await removeCompany();
  queryClient.invalidateQueries();
  await firebaseUnregisterDevice();
  history.push(ROUTES.login());
};
