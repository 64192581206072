export const ROUTES = {
  companies: () => "/companies",
  cst: (id: number | string) => `/route/cst/${id}`,
  cstEdit: (id: number | string) => `/route/cst/edit/${id}`,
  dashboard: () => "/",
  deviceRegister: () => "/device-register/logged-in",
  deviceRegisterPublic: () => "/device-register",
  deviceRegisterLogin: () => "/device-register/login",
  devices: () => `/devices`,
  jobCreate: (jobId?: number) => `/job/create${jobId ? `/${jobId}` : ""}`,
  jobEdit: (jobId: number | string) => `/job/edit/${jobId}`,
  job: (jobId: number | string, userId: number | string) =>
    `/job/${jobId}?userId=${userId}`,
  jobs: (owner?: "my" | "delegated") =>
    owner ? `/jobs?owner=${owner}` : "/jobs",
  jobRouteType: (jobId: number | string, userId?: number | string) =>
    `/route-job/${jobId}?${userId ? `userId=${userId}` : ""}`,
  jobManual: (jobId: number | string, userId?: number | string) =>
    `/manual-job/${jobId}?${userId ? `userId=${userId}` : ""}`,
  jobGeostick: (jobId: number | string, userId?: number | string) =>
    `/geostick-job/${jobId}?${userId ? `userId=${userId}` : ""}`,
  jobSignpost: (jobId: number | string) => `/job-signpost/${jobId}`,
  leicaCreate: () => "/leica/create",
  leicas: () => "/leicas",
  leica: (leicaId: number, leicaName: string) =>
    `/leica/${leicaId}/${leicaName}`,
  login: () => "/login",
  lostPassword: () => "/lost-password",
  mower: (mowerId: number) => `/mower/${mowerId}`,
  mowerCreate: () => "/mower/create",
  mowerEdit: (mowerId: number | string) => `/mower/edit/${mowerId}`,
  notifications: () => "/notifications",
  pendingUser: (userId: number) => `/pending-user/${userId}`,
  recoverPassword: (recoveryToken: string) =>
    `/recover-password/${recoveryToken}`,
  route: (routeId: number) => `/route/${routeId}`,
  routeCreate: () => "/route/create",
  routeCreateFromJob: (jobId: number | string) =>
    `/route/create-from-job/${jobId}`,
  routeClone: () => "/route/clone",
  routeEdit: (routeId: number | string) => `/route/edit/${routeId}`,
  routeChange: (routeId?: number | string) =>
    "/route/change" + (routeId ? `?routeId=${routeId}` : ""),
  routes: () => "/routes",
  statistics: () => "/statistics",
  support: () => "/support",
  ufon: (ufonId: number) => `/ufon/${ufonId}`,
  ufonCreate: () => "/ufon/create",
  ufonEdit: (ufonId: number | string) => `/ufon/edit/${ufonId}`,
  user: (userId: number) => `/user/${userId}`,
  userCreate: () => "/user/create",
  userEdit: (userId: number | string) => `/user/edit/${userId}`,
  users: () => "/users",
  userSelectCompany: () => "/user/select-company",
  userRegister: () => "/user-register",
};
